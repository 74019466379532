let heroSlider = new Swiper(".heroSlider", {
    slidesPerView: 1.2,
    spaceBetween: 50,
    loop: true,


    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        dynamicBullets: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    autoplay: {
        delay: 5000,
    },
    simulateTouch: false,



    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 5,
            simulateTouch: true,

        },

        // when window width is >= 640px
        640: {
            slidesPerView: 1,
            spaceBetween: 15
        },
        992: {
            simulateTouch: false,

        },
        1100: {
            slidesPerView: 1.2,
            spaceBetween: 50
        }
    }
});
