let iphProsucts = document.querySelectorAll('.iph--filter');
let count = 0;
let ansverArr = []

let iphFilterBtn = document.querySelectorAll('.filter__item');

iphFilterBtn.forEach(el => {
  let val = el.innerText;
  el.onclick = function (e) {
    let namePhone = e.srcElement.firstChild.nodeValue;
    filterProduct(iphProsucts, namePhone)
  };
});



function filterProduct(arr, value) {

  arr.forEach(element => {
    element.style.display = 'none';
    count++;
    element.dataset.iphid = count;
    let ansver = element.children;
    for (let i = 0; i < ansver.length; i++) {
      let item = ansver[4].innerText;
      ansverArr.push(item);

      const filterItems = (newarr, query) => {
        return newarr.filter(el => el.toLowerCase().indexOf(query.toLowerCase()) !== -1)

      }

      let superArray = filterItems(ansverArr, value);
      for (let i = 0; i < superArray.length; i++) {

        switch (item) {
          case superArray[i]:
            element.style.display = 'flex';
            mySwiper.update()

            break;
        }

      }
    }
  });
}

