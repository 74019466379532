// top-header-switch
let inputSwitch = document.querySelector("#switchHTInp").checked;
let containerSwitchInfo = document.querySelector(
  ".header__top-tooltip-info-container"
);
let labelSwitchHeaderTop = document.querySelector(".header-top__switch");
let headerTopSwitchTriangle = document.querySelector(".header-switch-triangle");

labelSwitchHeaderTop.addEventListener("click", () => {
  containerSwitchInfo.classList.toggle(
    "header__top-tooltip-info-container--active"
  );
  headerTopSwitchTriangle.classList.toggle("header-switch-triangle--active");
});



