try {

//  aw slider
let awSlider = new Swiper(".product-slider-aw", {
  slidesPerView: 1.1,
  spaceBetween: 15,
  pagination: {
    el: ".aw-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  navigation: {
    nextEl: '.btn-product-aw-next',
    prevEl: '.btn-product-aw-prev',
  },
  breakpoints: {
    640: {
      slidesPerView: 2.1,
    },
    768: {
      slidesPerView: 3.2,
    },
    1024: {
      slidesPerView: 3.3,
    },
  },
});

// iphone slider
let iphoneSlider = new Swiper(".product-slider-iph", {
  slidesPerView: 1.1,
  spaceBetween: 15,
  pagination: {
    el: ".iph-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  navigation: {
    nextEl: '.btn-product-iph-next',
    prevEl: '.btn-product-iph-prev',
  },
  breakpoints: {
    640: {
      slidesPerView: 2.1,
    },
    768: {
      slidesPerView: 3.2,
    },
    1024: {
      slidesPerView: 3.3,
    },
  },
});

} catch {

}
