window.setTimeout(function () {
  document.body.classList.add("loaded_hiding");

  document.body.classList.add("loaded");
  document.body.classList.remove("loaded_hiding");
  alertForClosePoin();
}, 2500);

// let close_point_container__closeBTN =
//   document.getElementById("idClosePointBtn");

// const alertForClosePoin = (e) => {
//   setTimeout(function () {
//     let close_point_container = document.getElementById("idClosePoin");
//     close_point_container.classList.add("c-point-container__active");
//     close_point_container__closeBTN.onclick = () => {
//       close_point_container.classList.remove("c-point-container__active");
//     };
//   }, 3000);
// };
