// jquery ajax left pop up form
let forms = $('form');

for (let i = 0; i < forms.length; i++) {
  alertForm(forms[i]);
}
// console.log(forms);
function alertForm(elem, index) {
  $(elem).submit(function (e) {
    e.preventDefault();
    let str = $(this).serialize();

    $.ajax({
      type: $(this).attr("method"),
      url: $(this).attr("action"),
      data: str,
      success: function (response) {

          if(response == 1){
          $(elem).children("#alert_for_form").html(`<div class="alert-mass bg-danger">
          <p class="alert-mass--text" id="textAletMass">
            Проверьте поле ввода номера, возможно вы пропустили одну или несколько цифр
          </p>
        </div>`);
          }
        else if(response == 2){

          $(elem).children("#alert_for_form").html(` <div class="alert-mass bg-danger">
          <p class="alert-mass--text" id="textAletMass">
            Заявка не отправлена, попробуйте позже
          </p>
        </div>`);

        }
        else if(response == 3){

          $(elem).children("#alert_for_form").html(` <div class="alert-mass bg-success">
          <p class="alert-mass--text" id="textAletMass">
            Выша заявка отправлена, мы Вам скоро перезвоним!
          </p>
        </div>`);
        $(elem).children("button").attr("disabled", true);
        $(elem).children("button").addClass("disabled-btn");

        }
      }
    });
  })
}


