let modelSelect = () => {
  let elementsModel = document.querySelectorAll('#selectModel');
  elementsModel.forEach(elModel => {
    let choices = new Choices(elModel, {
      shouldSort: false,
      itemSelectText: 'Выбрать',
      noResultsText: 'Не найдено',
      searchPlaceholderValue: 'Искать по моделям',
    });
  })
}
modelSelect();


let formVariantBreaking = () => {
  let elementsVariant = document.querySelectorAll('#formVariantBreaking');
  elementsVariant.forEach(elVariant => {
    let choices = new Choices(elVariant, {
      shouldSort: false,
      itemSelectText: 'Выбрать',
      // addItems: true,
      removeItemButton: true,
      noResultsText: 'Не найдено'
    });
  });

}
formVariantBreaking();

let formCitySelect = () => {
  let elCity = document.querySelectorAll('#formCitySelect');
  elCity.forEach(eCity => {
    let choices = new Choices(eCity, {
      shouldSort: false,
      itemSelectText: 'Выбрать',
      noResultsText: 'Не найдено',
      searchPlaceholderValue: 'Искать по моделям',
    });
  });

}
formCitySelect();

try {

// offer-calc
let typeTechnicaOfferCalc = () => {
  let typeTechnica = document.querySelector('#typeTechnicaOfferCalc');
  let choices = new Choices(typeTechnica, {
    shouldSort: false,
    itemSelectText: 'Выбрать',
    noResultsText: 'Не найдено',
    searchPlaceholderValue: 'Поиск...',
  });

}
typeTechnicaOfferCalc();

let typeSkinOfferCalc = () => {
  let typeSkin = document.querySelector('#typeSkinOfferCalc');
  let choices = new Choices(typeSkin, {
    shouldSort: false,
    itemSelectText: 'Выбрать',
    noResultsText: 'Не найдено',
    searchPlaceholderValue: 'Поиск...',
  });

}
typeSkinOfferCalc();
} catch {

}













