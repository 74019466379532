// offer calc


///типы гаджетов
// let smart = document.querySelector('#smart');
// let laptop = document.querySelector('#laptop');
// let tablet = document.querySelector('#tablet');
let manufactg = 100 + 250;
let typeTechnic = 0;
let percent = 14.5;

///поля
let price = document.querySelector('#offerPriceCount');
let priceInput = document.querySelector('#offerPriceCountInput');

let priceSell = document.querySelector('#offerPriceCountSell');
let priceSellInput = document.querySelector('#offerPriceCountSellInput');
let skinCount = 0;

let firstSelect = document.querySelector('#typeTechnicaOfferCalc');
let skinSelect = document.querySelector('#typeSkinOfferCalc');


try {

  firstSelect.addEventListener('change', (e) => {
    let index = e.target.value;
    switch (index) {
      case "none":
        clearTypeTech();
        break;
      case "Смартфон":
        percent = 14.5;
        calcTypeTech(200);
        break;
      case "Планшет":
        percent = 12.5;
        calcTypeTech(400);
        break;
      case "Смарт-Часы":
        percent = 14.5;
        calcTypeTech(200);
        break;
    }
    calcSkin(skinCount);
  });

  skinSelect.addEventListener('change', (e) => {
    let index = e.target.value;
    switch (index) {
      case "Прозрачная":
        if (firstSelect.value == 'Планшет') {
          percent = 12.5;
          calcSkin(0);
          break;
        }
        calcSkin(0);
        break;
      case "Матовая":
        if (firstSelect.value == 'Планшет') {
          percent = 12.5;
          calcSkin(0);
          break;
        }

        calcSkin(50);
        break;
      case "Anti-Blue":
        if (firstSelect.value == 'Планшет') {
          percent = 12.5;
          calcSkin(0);
          break;
        }
        calcSkin(50);
        break;
      case "Тонированная":
        if (firstSelect.value == 'Планшет') {
          calcSkin(-750);
          break;
        }
        calcSkin(250);
        break;
    }

  });

  //  clear
  function clearTypeTech() {
    typeTechnic = 0;
  }

  ///first
  function calcTypeTech(count) {
    typeTechnic = count + manufactg;
    price.textContent = typeTechnic;
    priceSell.textContent = Math.round(typeTechnic - typeTechnic / 100 * percent);
    priceInput.value = price.textContent;
    priceSellInput.value = priceSell.textContent;
  }

  function calcSkin(count) {
    skinCount = count;
    price.textContent = 0;
    if (typeTechnic < 1) {
      price.textContent = 0;
      priceSell.textContent = 0;
    }
    else if (typeTechnic > 0) {
      price.textContent = typeTechnic + skinCount;
      priceSell.textContent = Math.round(Number(price.textContent) - Number(price.textContent) / 100 * percent);
      priceInput.value = price.textContent;
      priceSellInput.value = priceSell.textContent;
    }
  }


} catch {

}





