let typesInfoBlock = document.querySelector('.types-trigger'),
    typesBntTrigger = document.querySelectorAll('.popup-left-trigger'),
    typesBntTriggerClose = document.querySelector('.types-trigger__close'),
    typesformContainer = document.querySelector('.types-trigger__container'),
    bodyTag = document.querySelector('body');

typesBntTrigger.forEach(elem => {
    elem.addEventListener('click', () => {
        typesInfoBlock.classList.toggle('types-trigger-active');
        typesformContainer.classList.toggle('types-trigger__container-active');
        bodyTag.style.overflow = "hidden";
    })
});

typesBntTriggerClose.addEventListener('click', () => {
    typesInfoBlock.classList.remove('types-trigger-active');
    typesformContainer.classList.remove('types-trigger__container-active');
    bodyTag.style.overflow = "";
})
typesInfoBlock.addEventListener('click', () => {
    typesInfoBlock.classList.remove('types-trigger-active');
    typesformContainer.classList.remove('types-trigger__container-active');
    bodyTag.style.overflow = "";
})
