document.addEventListener("DOMContentLoaded", function(event) {
    // menu mobile
    let navBtn = document.querySelectorAll('.burger-mobile');
    let navBlock = document.getElementById('navMenuList');
    let navBtnIcon = document.querySelector('.burger-toggle-icon');


    navBtn.forEach(e => {
        e.addEventListener('click', () => {
            navBlock.classList.toggle('nav-open');
            e.classList.toggle('burger-active');
            navBtnIcon.classList.toggle('burger-toggle-icon-active');

        });
    });


});