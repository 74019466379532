let swiper = new Swiper(".reviews-slider", {
    slidesPerView: 1,
    loop: true,
    // effect: 'fade',
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },

    pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
    },

    autoplay: {
        delay: 3500,
    },
});
