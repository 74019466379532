let heroSlider = new Swiper(".often-request__slider", {
    slidesPerView: 6,
    spaceBetween: 15,
    freeMode: true,

    navigation: {
        nextEl: ".often-arrow-next",
        prevEl: ".often-arrow-prev",
    },


    breakpoints: {

        320: {
            slidesPerView: 2,
        },
        370: {
            slidesPerView: 2.3,
        },
        470: {
            slidesPerView: 2.6,
        },
        580: {
            slidesPerView: 3.3,
        },
        650: {
            slidesPerView: 3.8,
        },
        820: {
            slidesPerView: 4.3,
        },
        1024: {
            slidesPerView: 6.3,
        }
    }
});
